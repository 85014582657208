import api from '@/utils/api'

const globalMixin = {
  computed: {
    isLoading() {
      return this.$store.state.isLoading > 0
    },
    endpointUid() {
      return this.$route.params.endpoint
    },
    systemUid() {
      return this.$route.params.system
    },
    cacheData() {
      const cacheMap = this.$store.state.cacheMap || {}
      const uid = cacheMap.__ALL__ ? '__ALL__' : this.$route.params.endpoint
      const val = cacheMap[uid]
      if (val) {
        const params = {}
        if (
          typeof val === 'string' &&
          (uid !== '__ALL__' || Object.values(cacheMap).every(v => v === val))
        ) {
          params.flag = val
        }
        if (uid !== '__ALL__') {
          params.byEndpoint = true
        }
        return params
      }
    }
  },
  methods: {
    localize(v) {
      return this.$options.filters.localize(v)
    },
    toast(text, type) {
      type =
        {
          warn: 'warning',
          ok: 'success',
        }[type] || type
      this.$toast[type || 'info'](this.localize(text))
    },
    returnPage(payload) {
      let parts = this.$route.path.split('/').slice(0, -1)
      if (typeof payload === 'string') {
        parts.push(payload)
      } else if (typeof payload === 'number' && payload > 1) {
        parts = parts.slice(0, (payload - 1) * -1)
      }
      this.$router.push({
        path: parts.join('/'),
      })
    },
    async clearCache({ byEndpoint = false, flag = undefined } = this.cacheData) {
      if (flag && !['StoragesOnly', 'SubscribesOnly'].includes(flag)) {
        console.error('Unknown ClearCache flag:', flag)
        return
      }
      const params = {}
      if (byEndpoint) {
        try {
          params.Endpoint = (await api.get('endpoints')).find(
            obj => `${obj.uid}` === `${this.$route.params.endpoint}`
          )?.code
        } catch (e) {
          this.toast(e.message, 'error')
          return
        }
      }
      if (flag) { params[flag] = 1 }
      try {
        await api.post('clearCache', params)
      } catch (e) {
        this.toast(e.message, 'error')
      }
    }
  }
}

export default globalMixin
